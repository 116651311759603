document.addEventListener("DOMContentLoaded", () => {
  const sphereElements = document.querySelectorAll("[data-photosphere]");
  if (sphereElements.length > 0) {
    import("@photo-sphere-viewer/core").then(({ Viewer }) => {
      for (let element of sphereElements) {
        new Viewer({
          container: element,
          panorama: element.dataset.photosphere,
        });
      }
    });
  }
});
