function pictureThemeOverride() {
  document.querySelectorAll("picture").forEach((picture) => {
    const sources = picture.querySelectorAll("source");
    sources.forEach((source) => {
      // Store the original media attribute
      if (!source.dataset.media) {
        source.dataset.media = source.getAttribute("media");
      }

      if (document.body.classList.contains("light")) {
        // Force light mode
        console.info("Source", source);
        if (source.getAttribute("media") === "(prefers-color-scheme: light)") {
          console.info("Forcing light mode");
          source.removeAttribute("media");
        } else if (source.dataset.media === "(prefers-color-scheme: dark)") {
          source.setAttribute("media", "not all");
        }
      } else if (document.body.classList.contains("dark")) {
        // Force dark mode
        if (source.getAttribute("media") === "(prefers-color-scheme: dark)") {
          console.info("Forcing dark mode");
          source.removeAttribute("media");
        } else if (source.dataset.media === "(prefers-color-scheme: light)") {
          source.setAttribute("media", "not all");
        }
      } else {
        // Auto mode
        if (
          !source.getAttribute("media") ||
          source.getAttribute("media") === "not all"
        ) {
          source.setAttribute("media", source.dataset.media);
        }
      }
    });
  });
}

const observerCallback = (mutationList, _observer) => {
  for (const mutation of mutationList) {
    if (mutation.type === "attributes" && mutation.attributeName === "class") {
      pictureThemeOverride();
    }
  }
};

document.addEventListener("DOMContentLoaded", () => {
  const observer = new MutationObserver(observerCallback);
  observer.observe(document.body, {
    attributes: true,
    attributeFilter: ["class"],
  });

  const initialColorScheme = localStorage.getItem("color-scheme");
  if (initialColorScheme) {
    document.body.classList.remove("light", "dark", "auto");
    document.body.classList.add(localStorage.getItem("color-scheme"));
  }

  const themeButton = document.querySelector("[data-role='theme-toggle']");

  themeButton.addEventListener("click", () => {
    const colorScheme = localStorage.getItem("color-scheme");

    switch (colorScheme) {
      case "dark":
        localStorage.setItem("color-scheme", "light");
        break;
      case "light":
        localStorage.setItem("color-scheme", "auto");
        break;
      case "auto":
        localStorage.setItem("color-scheme", "dark");
        break;
      default:
        localStorage.setItem("color-scheme", "dark");
    }

    document.body.classList.remove("light", "dark", "auto");
    document.body.classList.add(localStorage.getItem("color-scheme"));

    // const isDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
    // const isLightMode = window.matchMedia("(prefers-color-scheme: light)").matches;
    // const isNotSpecified = window.matchMedia("(prefers-color-scheme: no-preference)").matches;
    // const hasNoSupport = !isDarkMode && !isLightMode && !isNotSpecified;
    //
    // console.log("click", isDarkMode, isLightMode, isNotSpecified, hasNoSupport);
  });
});
