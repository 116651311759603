import ClipboardJS from "clipboard";

if (ClipboardJS.isSupported()) {
  const snippets = document.querySelectorAll("pre.highlight");

  [].forEach.call(snippets, function(snippet) {
    snippet.firstChild.insertAdjacentHTML(
      "afterend",
      `<footer><button class="btn btn-default btn-ghost" data-clipboard-snippet>Copy</button></footer>`
    );
  });

  const clipboardSnippets = new ClipboardJS("[data-clipboard-snippet]", {
    target: function(trigger) {
      return trigger.closest("footer").previousElementSibling;
    },
  });

  clipboardSnippets.on("success", function(e) {
    e.clearSelection();

    e.trigger.innerText = "Copied!";

    setTimeout(() => {
      e.trigger.innerText = "Copy";
    }, 2500);
  });

  clipboardSnippets.on("error", function(e) {
    console.error("Action:", e.action);
    console.error("Trigger:", e.trigger);

    e.trigger.innerText = "Error";
    e.trigger.className = "btn btn-error btn-ghost";

    setTimeout(() => {
      e.trigger.innerText = "Copy";
      e.trigger.className = "btn btn-default btn-ghost";
    }, 5000);
  });
}
