import "$styles/index.css";

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}";

// Import all images from frontend/images
import images from "../images/**/*.{jpg,png,svg,gif}";
Object.entries(images).forEach((image) => image);

console.info("Bridgetown is loaded!");

import { lazyLoad } from "unlazy";
import "lite-youtube-embed";

import "./snippets.js";
import "./theme.js";
import "./gallery.js";
import "./photosphere.js";

document.addEventListener("DOMContentLoaded", () => {
  lazyLoad();
});
