document.addEventListener("DOMContentLoaded", () => {
  const galleryElements = document.querySelectorAll("[data-gallery]");
  if (galleryElements.length > 0) {
    import("glightbox").then((module) => {
      const GLightbox = module.default;

      for (let element of galleryElements) {
        const galleryName = element.dataset.gallery;
        GLightbox({
          selector: `.lightbox-${galleryName}`,
        });
      }
    });
  }
});
